import React, { useState, useEffect, useRef } from "react";
// import Skeleton from "react-loading-skeleton";
import HtmlParser from "react-html-parser";
import moment from "moment";
import RichText from "./RichText";
import RichTextArea from "./RichTextArea";
import TextareaAutosize from "react-textarea-autosize";
import { Link } from "react-router-dom";
import { Row, Col, Card, UncontrolledTooltip } from "reactstrap";
import Select, { components } from "react-select";
import Avatar from "./Avatar";
import DatepickerDiv from "./DatepickerNew";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Api from "../Utils/API";
import { Api as ApiImage } from "../Utils/consts";
import {
  getFirstWord,
  download,
  multipleDownload,
  getCookie,
} from "../Utils/helper";
import Input from "./Input";
import ScroolUser from "./ScroolUser";
// import NumberPickerData from "./NumberPickerData";
import template_icon from "../assets/images/template-icon.svg";
import attachment from "../assets/images/attachment.svg";
import iconCheck from "../assets/images/icon-check.png";
import no_email_selected_icon from "../assets/images/no_email-selected.svg";
import iconCheckWhite from "../assets/images/icon-check-white.png";
import subtaskIcon from "../assets/images/subtask-icon.svg";
import copyIcon from "../assets/images/copy-link-icon.svg";
import closeIcon from "../assets/images/closeIcon.svg";
import userIcon from "../assets/images/userIcon.png";
// import clockIcon from "../assets/images/clockIcon.svg";
import plusIcon from "../assets/images/plusIcon.svg";
import pdfFile from "../assets/images/pdfFile.svg";
// import penIcon from "../assets/images/penIcon.png";
import emailIcon from "../assets/images/request-form-email-icon.svg";
import commentIcon from "../assets/images/request-form-comment-icon.svg";
import addAttachmentIcon from "../assets/images/add-attachment.svg";
import hubstaffIcon from "../assets/images/hubstaff-icon.svg";
// import addAttachmentPngIcon from "../assets/images/add-attachment.png";
import TimeAdjustmentForm from "./TimeAdjustmentForm";
import { PropertyInformation } from "./PropertyInformation";
import ConfirmSend from "./ConfirmSend";
import ErrorAlert from "./ErrorAlert";
import SuccessAlert from "./SuccessAlert";
import UnderDevelopmentAlert from "../components/UnderDevelopmentAlert";
import SubmitQA from "../components/PopupForms/SubmitQA";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTooltip from "react-tooltip";
// import TaskProgressSlider from "./Common/TaskProgressSlider";
import AttachmentFile from "../components/AttachmentFile";
import Switch from "../components/Switch";
import TaskActivity from "../components/TaskActivity";
import TimePicker from "../components/TimeTracker/TimePicker";
import SendEmail from "./PopupForms/SendEmail";

const ReactSwal = withReactContent(Swal);
const logged_in_user = getCookie("userDetails");
let user_details = "";
if (logged_in_user) user_details = JSON.parse(logged_in_user);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #ECECF5",
    color: state.isSelected ? "#fff" : "#8890AB",
    padding: 6,
    fontSize: 12,
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#AFB6CB",
  }),
  control: (base, state) => ({
    ...base,
    border: "0 !important",
    boxShadow: "0 !important",
    "&:hover": {
      border: "0 !important",
    },
  }),
  singleValue: (base) => ({
    ...base,
    marginLeft: -5,
  }),
};

// const LoadingDiv = () => {
//   return (
//     <React.Fragment>
//       <div className="row row-comments-list">
//         <div className="col-9">
//           <Skeleton circle={true} height={32} width={32} count={1} />
//           <span className="author">
//             <Skeleton className="ml-2" height={18} width={100} count={1} />
//           </span>
//           <div className="mt-1">
//             <Skeleton height={12} width={"100%"} count={1} />
//             <Skeleton height={12} width={"100%"} count={1} />
//             <Skeleton height={12} width={"100%"} count={1} />
//           </div>
//         </div>
//         <div className="col-3 text-right delete">
//           <Skeleton height={18} width={18} count={1} />
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

const RequestForm = React.memo((props) => {
  let defaultDate = new Date(),
    tomorrow = new Date(defaultDate);

  tomorrow.setDate(tomorrow.getDate() + 1);
  defaultDate.setDate(
    tomorrow.getDay() == 6 || tomorrow.getDay() == 0
      ? defaultDate.getDate()
      : defaultDate.getDate() + 1
  );

  const [comments, setComments] = useState([]);
  let [report, setReport] = useState({});
  const [state, setState] = useState({
    psOpen: false,
    qaOpen: false,
    timeOpen: false,
    isSave: false,
    isSaveTime: false,
    loadAdminAssignee: false,
    loadPrimaryAssignee: false,
    loadQAAssignee: false,
    loadRequestor: false,
    loadStatus: false,
    isComment: false,
    isSubtask: false,
    isAttachment: false,
    form_type: "request_details",
    isDone: 0,
    details: [],
    propertyID: "",
    propertyID_options: [],
    title: "",
    primaryAid: "",
    adminDetails: "",
    primaryAdetails: "",
    coders: props.coders,
    hr: "00",
    min: "00",
    actualTimeHr: "00",
    actualTimeMin: "00",
    estimateTimeHr: "00",
    estimateTimeMin: "00",
    billableTimeHr: "00",
    billableTimeMin: "00",
    timeAdjustmentsHr: "",
    timeAdjustmentsMin: "",
    addTimeAdjustments: false,
    taOpen: false,
    actualTimeAction: 1,
    requestor: "",
    pricing_method: "",
    status: "",
    dueDate: props.details ? props.details.due_date : defaultDate,
    startDate: props.details ? props.details.start_date : defaultDate,
    report_details:
      props.details && props.details.report ? props.details.report : "",
    QAdetails: "",
    resquestType: "",
    newImages: [],
    description: "",
    description2: "",
    comment: "",
    comment_attachments: [],
    errors: {},
    created_by: "",
    pricing_methods_options: [],
    requestor_options: [],
    statuses_options: [],
    selectedFiles: [],
    subtask: [],
    clarified_description_to_client: "",
    requestType_options: [],
    requestType: "",
    clearRich: false,
    copyLink: false,
    propertyInfo: "",
    openPropertInfo: false,
    showProposal: false,
    sendingProposal: false,
    task_saved: false,
    primaryAssigneeError: null,
    qaAssigneeError: null,
    preload_duedate: false,
    preload_requestor: false,
    preload_status: false,
    progress: 0,
    board: false,
    dueDateUpdate: "",
    isSyncing: false,
    attachments: [],
    deploymentNotes: "",
    editDeplymentNotes: false,
    typingNotesTimeout: 0,
    requiresDeployments: false,
    company: "",
    project: "",
    activities: [],
    reports: [],
  });
  const notesTA = useRef();

  const focusNotesTA = () => {
    if (notesTA.current) notesTA.current.focus();
  };

  const getTaskActivities = (id) => {
    Api.getTaskActivities(id)
      .then((result) => {
        setState((prevState) => ({
          ...prevState,
          activities: result.data,
        }));
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  const getTaskReports = (id) => {
    Api.getTaskReports(id)
      .then((result) => {
        setState((prevState) => ({
          ...prevState,
          reports: result.data,
        }));
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  useEffect(() => {
    let pricing_methods_options = [],
      requestor = props.requestor,
      requestor_options = [],
      statuses_options = [],
      requestType_options = [],
      detailsRequestorObject = [],
      detailsPricingObject = [],
      requestTypeObject = [],
      statusTypeObject = [],
      propertyTypeObject = "",
      statuses = props.statuses,
      requestType = props.request_types,
      billable_time =
        props.details && props.details.billable_time
          ? props.details.billable_time
          : "",
      actual_time_used =
        props.details && props.details.actual_time_used
          ? props.details.actual_time_used
          : "",
      estimated_time =
        props.details && props.details.estimated_time
          ? props.details.estimated_time
          : "",
      time_adjustments =
        props.details && props.details.time_adjustments
          ? props.details.time_adjustments
          : "",
      detailsRequestor =
        props.details && props.details.requestor ? props.details.requestor : "",
      detailspricing_method =
        props.details && props.details.pricing_method
          ? props.details.pricing_method
          : pricing_methods_options[0],
      requestTypeDetails =
        props.details && props.details.request_type
          ? props.details.request_type
          : requestType_options[0],
      statusDetails =
        props.details && props.details.status ? props.details.status : "",
      propertyDetails =
        props.details && props.details.property ? props.details.property : "",
      progress =
        props.details && props.details.progress ? props.details.progress : 0;

    for (let req of requestor) {
      requestor_options.push({
        value: req.id,
        label: req.firstname + " " + req.surname,
        subLabel: req.company_name,
      });
    }

    for (let status of statuses) {
      statuses_options.push({
        value: status.id,
        label: status.title,
      });
    }
    for (let request_type of requestType) {
      requestType_options.push({
        value: request_type.id,
        label: request_type.title,
      });
    }

    //REQUESTOR
    if (detailsRequestor) {
      detailsRequestorObject.push({
        value: detailsRequestor.id,
        label: detailsRequestor.firstname + " " + detailsRequestor.surname,
        subLabel: detailsRequestor.company_name,
        email: detailsRequestor.email,
        companyId: detailsRequestor.company_id,
      });

      companyProperties(detailsRequestorObject); // Get property options after getting the requestor
    }

    //PRICING METHOD
    if (detailspricing_method) {
      detailsPricingObject.push({
        value: detailspricing_method.id,
        label: detailspricing_method.name,
      });
    }

    //REQUEST TYPE
    if (requestTypeDetails) {
      requestTypeObject.push({
        value: requestTypeDetails.id || requestTypeDetails.value,
        label: requestTypeDetails.title || requestTypeDetails.label,
      });
    }

    //STATUS
    if (statusDetails) {
      statusTypeObject.push({
        value: statusDetails.id,
        label: statusDetails.title,
      });
    }

    //PROPERTY
    if (propertyDetails) {
      propertyTypeObject = {
        value: propertyDetails.id,
        label: propertyDetails.name,
      };
    }

    const temp = time_adjustments.split(":");
    const addTimeAdjustments = temp[0] >= 0;
    const timeAdjustmentsHr = addTimeAdjustments ? temp[0] : -1 * temp[0];
    const timeAdjustmentsMin = temp[1];

    // convert 0:0 to 00:00 actual time format
    let actual_time_hours = actual_time_used.split(":")[0],
      actual_time_minutes = actual_time_used.split(":")[1];
    actual_time_hours = ("0" + actual_time_hours).slice(-2);
    actual_time_minutes = ("0" + actual_time_minutes).slice(-2);

    // convert 0:0 to 00:00 estimated time format
    let estimate_hours = estimated_time.split(":")[0],
      estimate_minutes = estimated_time.split(":")[1];
    estimate_hours = ("0" + estimate_hours).slice(-2);
    estimate_minutes = ("0" + estimate_minutes).slice(-2);

    // convert 0:0 to 00:00 billable time format
    let billable_hours = billable_time.split(":")[0],
      billable_minutes = billable_time.split(":")[1];
    billable_hours = ("0" + billable_hours).slice(-2);
    billable_minutes = ("0" + billable_minutes).slice(-2);

    setState({
      ...state,
      coders: props.coders,
      clarified_description_to_client: props.details
        ? props.details.clarified_description_to_client
        : "",
      description: props.details ? props.details.description : "",
      details: props.details,
      subtask: props.details ? props.details.subtasks : [],
      title: props.details ? props.details.title : "",
      actualTimeHr: actual_time_hours,
      actualTimeMin: actual_time_minutes,
      estimateTimeHr: estimate_hours,
      estimateTimeMin: estimate_minutes,
      billableTimeHr: billable_hours,
      billableTimeMin: billable_minutes,
      timeAdjustmentsHr: time_adjustments ? timeAdjustmentsHr : "00",
      timeAdjustmentsMin: time_adjustments ? timeAdjustmentsMin : "00",
      addTimeAdjustments: addTimeAdjustments,
      pricing_methods_options: props.pricing_methods,
      requestor_options: requestor_options,
      requestor: detailsRequestorObject,
      adminDetails:
        props.details && props.details.admin_assignee
          ? props.details.admin_assignee
          : "",
      primaryAdetails:
        props.details && props.details.primary_assignee
          ? props.details.primary_assignee
          : "",
      QAdetails:
        props.details && props.details.qa_assignee
          ? props.details.qa_assignee
          : "",
      statuses_options: statuses_options,
      requestType_options: requestType_options,
      pricing_method: detailsPricingObject,
      propertyID: propertyTypeObject,
      requestType: requestTypeObject,
      status: statusTypeObject,
      is_done: props.details ? props.details.is_done : false,
      created_by:
        props.details && props.details.created_by
          ? props.details.created_by.firstname +
            " " +
            props.details.created_by.surname
          : "",
      nameIcon:
        props.details && props.details.created_by
          ? getFirstWord(props.details.created_by.firstname) +
            " " +
            getFirstWord(props.details.created_by.surname)
          : "",
      showProposal: props.details && props.details.proposed_at ? false : true,
      task_saved:
        state.clarified_description_to_client !=
        (props.details ? props.details.clarified_description_to_client : null),
      report_details:
        props.details && props.details.report ? props.details.report : "",
      progress,
      deploymentNotes: props.details.deployment_notes ?? "",
      editDeplymentNotes: false,
      requiresDeployments: props.details.requires_deployments,
      company: props.details.company,
      project: props.details.project?.text,
    });
    getTaskActivities(props.details.id);
    getTaskReports(props.details.id);
  }, [props.details]);

  // !!! THIS FUNCTION IS STILL INCOMPLETE
  // const afterNewProps = () => {
  //   let attachments = document.getElementsByClassName("w100");
  //   if(attachments.length) {
  //     attachments.map((att) => {
  //       att.addEventListener("click", console.log('show image'));
  //     });
  //   }
  // }

  useEffect(() => {
    setComments(props.comments);
    // afterNewProps();
  }, [props.comments]);

  useEffect(() => {
    if(state.reports.length) {
      // merge comments and reports
      let temp = [...comments, ...state.reports];

      // sort array
      temp = temp.sort(function(a,b) {
        console.log("::a.date", a.date);
        console.log("::b.date", b.date);
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });

      setComments(temp);
    }
  }, [state.reports]);

  useEffect(() => {
    console.log("::comments", comments);
  }, [comments]);

  function ButtonUser(props) {
    return (
      <button
        className="btn btn-cirle-dash ml-1"
        onClick={props.action}
        disabled={props.load}>
        {props.load && (
          <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
        )}
        <img src={userIcon} />
      </button>
    );
  }

  function ImageAttachments(props) {
    return (
      <div className="image-attachment">
        {props.data.map((item, i) => {
          return <img key={i} src={item.path} />;
        })}
      </div>
    );
  }

  function ButtonUserOnly(props) {
    return (
      <div className="primary-assignee-div">
        <div className="close-avatar" onClick={props.actionDelete}>
          <button className="btn-cirle">
            {props.load ? (
              <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
            ) : (
              <i className=" fa fa-times" style={{ fontSize: 10 }}></i>
            )}
          </button>
        </div>
        <div onClick={props.action}>
          <Avatar
            name={` ${getFirstWord(props.details.firstname)} ${getFirstWord(
              props.details.surname
            )} `}
            round={true}
            size="32"
            color="#f44336"
            image={props.details.avatar ? ApiImage + props.details.avatar : ""}
            className="btn-cirle-avatar"
          />
        </div>
      </div>
    );
  }

  const adminAssignee = (e) => {
    setState((prevState) => ({
      ...prevState,
      loadAdminAssignee: true,
      adminOpen: false,
    }));
    let coders = state.coders,
      selectedData = e,
      indexData = coders.indexOf(selectedData),
      newObject = { index: indexData, ...selectedData },
      selectedCoders = state.primaryAdetails,
      params = {
        task_id: state.details.id,
        admin_assignee: selectedData.id,
      };

    if (state.adminDetails && state.adminDetails.id == selectedData.id) {
      setState({ ...state, loadAdminAssignee: false });
      return deleteAdminAssignee();
    }

    Api.adminAssigneeUpdate(params)
      .then((result) => {
        if (state.admindetails) {
          // REMOVE SELECTED ASSIGNEE FROM LIST
          let lastIndex = coders.length - 1; // get the last index of coders
          let indexToAdd = selectedCoders.index; // get index of record to add

          // ADD PREVIOUSLY SELECTED ASSIGNEE BACK TO THE LIST
          if (
            indexToAdd >= lastIndex &&
            selectedCoders.id > coders[lastIndex].id
          )
            coders.push(selectedCoders);
        }

        setState({
          ...state,
          adminDetails: newObject,
          loadAdminAssignee: false,
        });
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";
        setState({ ...state, loadAdminAssignee: false });
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  const primaryAssignee = (e) => {
    setState((prevState) => ({
      ...prevState,
      loadPrimaryAssignee: true,
      psOpen: false,
    }));
    let coders = state.coders,
      selectedData = e,
      indexData = coders.indexOf(selectedData),
      newObject = { index: indexData, ...selectedData },
      selectedCoders = state.primaryAdetails,
      params = {
        task_id: state.details.id,
        primary_assignee: selectedData.id,
      };

    if (state.primaryAdetails && state.primaryAdetails.id == selectedData.id) {
      setState({ ...state, loadPrimaryAssignee: false });
      return deletePrimaryAssignee();
    }

    Api.primaryUpdate(params)
      .then((result) => {
        if (state.primaryAdetails) {
          // REMOVE SELECTED ASSIGNEE FROM LIST
          let lastIndex = coders.length - 1; // get the last index of coders
          let indexToAdd = selectedCoders.index; // get index of record to add

          // ADD PREVIOUSLY SELECTED ASSIGNEE BACK TO THE LIST
          if (
            indexToAdd >= lastIndex &&
            selectedCoders.id > coders[lastIndex].id
          )
            coders.push(selectedCoders);
        }

        setState({
          ...state,
          primaryAdetails: newObject,
          loadPrimaryAssignee: false,
        });
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";
        setState({ ...state, loadPrimaryAssignee: false });
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  const qaAssignee = (e) => {
    setState((prevState) => ({
      ...prevState,
      loadQAAssignee: true,
      qaOpen: false,
    }));
    let coders = state.coders,
      selectedData = e,
      indexData = coders.indexOf(selectedData),
      newObject = { index: indexData, ...selectedData },
      selectedCoders = state.QAdetails,
      params = {
        task_id: state.details.id,
        qa_assignee: e.id,
      };

    if (state.QAdetails && state.QAdetails.id == selectedData.id) {
      setState({ ...state, loadQAAssignee: false });
      return deleteQAAssignee();
    }

    Api.qaUpdate(params)
      .then((result) => {
        setState({ ...state, loadQAAssignee: false });

        if (state.QAdetails) {
          let lastIndex = coders.length - 1; // get the last index of coders
          let indexToAdd = selectedCoders.index; // get index of record to add

          if (
            indexToAdd >= lastIndex &&
            selectedCoders.id > coders[lastIndex].id
          )
            coders.push(selectedCoders);
        }

        setState({ ...state, qaOpen: false, QAdetails: newObject });
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";
        setState({ ...state, loadQAAssignee: false });
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  const selectAdmin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.body.classList.add("admin-assignee-show");
    document
      .querySelector(".admin-assignee-content")
      .classList.remove("toogle-hide");
    setState({ ...state, adminOpen: true, psOpen: false, qaOpen: false });
  };

  const selectPS = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.body.classList.add("primary-assignee-show");
    document
      .querySelector(".primary-assignee-content")
      .classList.remove("toogle-hide");
    setState({ ...state, adminOpen: false, psOpen: true, qaOpen: false });
  };

  const selectQA = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.body.classList.add("primary-qa-show");
    document
      .querySelector(".primary-qa-content")
      .classList.remove("toogle-hide");
    setState({ ...state, adminOpen: false, qaOpen: true, psOpen: false });
  };

  const deleteAdminAssignee = (e) => {
    setState((prevState) => ({ ...prevState, loadAdminAssignee: true }));
    let params = { task_id: state.details.id };

    Api.removeAdminAssignee(params)
      .then((result) => {
        setState({ ...state, adminDetails: "", loadAdminAssignee: false });
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deletePrimaryAssignee = (e) => {
    setState((prevState) => ({ ...prevState, loadPrimaryAssignee: true }));
    let params = { task_id: state.details.id };

    Api.removePrimary(params)
      .then((result) => {
        setState({ ...state, primaryAdetails: "", loadPrimaryAssignee: false });
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteQAAssignee = (e) => {
    setState((prevState) => ({ ...prevState, loadQAAssignee: true }));
    let params = { task_id: state.details.id };

    Api.removeQA(params)
      .then((result) => {
        setState({ ...state, QAdetails: "", loadQAAssignee: false });
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editActual = (e) => {
    e.preventDefault();
    document.body.classList.add("actual-time-show");
    document.querySelector(".timer-picker-div").classList.remove("toogle-hide");
    setState({
      ...state,
      hr: state.actualTimeHr,
      min: state.actualTimeMin,
      timeOpen: true,
      actualTimeAction: 1,
    });
  };

  const editEstimate = (e) => {
    e.preventDefault();
    document.body.classList.add("actual-time-show");
    document.querySelector(".timer-picker-div").classList.remove("toogle-hide");
    setState({
      ...state,
      hr: state.estimateTimeHr,
      min: state.estimateTimeMin,
      timeOpen: true,
      actualTimeAction: 2,
    });
  };

  function updateTime(e) {
    const params = {
      task_id: state.details.id,
      actual_time: e.hr + ":" + e.min, // for actual time parameter
      estimateTime: e.hr + ":" + e.min, // for estimate time parameter
    };

    e.hr = ("0" + e.hr).slice(-2);
    e.min = ("0" + e.min).slice(-2);

    if (e.type === 1) return updateTimeAPI(e, params);
    // update time used
    else return updateEstimateAPI(e, params); // update estimate time
  }

  const updateTimeAPI = (time) => {
    time.hr = ("0" + time.hr).slice(-2);
    time.min = ("0" + time.min).slice(-2);

    const params = {
      task_id: state.details.id,
      time: `${time.hr}:${time.min}`,
    };

    Api.updateTimeUsed(params)
      .then((result) => {
        setState({
          ...state,
          isSaveTime: false,
          timeOpen: false,
          actualTimeHr: time.hr,
          actualTimeMin: time.min,
          hr: time.hr,
          min: time.min,
        });
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSaveTime: false, timeOpen: false });
        console.log(error);
      });
  };

  const updateEstimateAPI = (time) => {
    time.hr = ("0" + time.hr).slice(-2);
    time.min = ("0" + time.min).slice(-2);

    const params = {
      task_id: state.details.id,
      time: `${time.hr}:${time.min}`,
    };

    Api.updateEstimateUsed(params)
      .then(({ data }) => {
        let billableTime = data.billableTime;
        let billable_hours = billableTime.split(":")[0],
          billable_minutes = billableTime.split(":")[1];
        billable_hours = ("0" + billable_hours).slice(-2);
        billable_minutes = ("0" + billable_minutes).slice(-2);

        setState({
          ...state,
          isSaveTime: false,
          timeOpen: false,
          estimateTimeHr: time.hr,
          estimateTimeMin: time.min,
          hr: time.hr,
          min: time.min,
          billableTimeHr: billable_hours,
          billableTimeMin: billable_minutes,
        });
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSaveTime: false, timeOpen: false });
        console.log(error);
      });
  };

  const updateBillableAPI = (time) => {
    time.hr = ("0" + time.hr).slice(-2);
    time.min = ("0" + time.min).slice(-2);

    const params = {
      task_id: state.details.id,
      time: `${time.hr}:${time.min}`,
    };

    Api.updateBillableTime(params)
      .then((result) => {
        setState({
          ...state,
          isSaveTime: false,
          timeOpen: false,
          billableTimeHr: time.hr,
          billableTimeMin: time.min,
          hr: time.hr,
          min: time.min,
        });
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSaveTime: false, timeOpen: false });
        console.log(error);
      });
  };

  function updateTimeAdjustments(adjustments) {
    let details = props.details;
    details.time_adjustments =
      (adjustments.is_add ? "" : "-") + adjustments.hr + ":" + adjustments.min;
    props.updateDetails(details);
    setState({
      ...state,
      timeAdjustmentsHr: adjustments.hr,
      timeAdjustmentsMin: adjustments.min,
      addTimeAdjustments: adjustments.is_add,
      taOpen: false,
    });
  }

  function handleImageChange(e) {
    e.preventDefault();
    setState({ ...state, isAttachment: true });

    let files = e.target.files;
    let mb_25valid = 1048576 * 1; // 1048576 = 1MB;
    let validFiles = [];
    let isValid = true;
    let data = new FormData();
    for (let index = 0; index < files.length; index++) {
      let dataFiles = files[index];
      var fileType = files[index].type;
      let isImage = fileType.search("image") >= 0 ? true : false;
      isValid = files[index].size > mb_25valid ? false : true;
      dataFiles.isValid = isValid;
      dataFiles.isImage = isImage;
      dataFiles.preview = URL.createObjectURL(files[index]);
      dataFiles.formattedSize = formatBytes(files[index].size);
      data.append("task_id", state.details.id);
      data.append("files[]", files[index]);
      validFiles.push(dataFiles);
    }

    Api.updateAttachment(data)
      .then((result) => {
        let nAttachments = [];
        let taskAttachments = result.data;
        if (taskAttachments.length) {
          taskAttachments.map((att) => {
            nAttachments.push(att);
          });
        }

        setState({ ...state, isSubmit: false, attachments: nAttachments });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, isSubmit: false });
      });
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024,
      dm = decimals < 0 ? 0 : decimals,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const editRequest = (e) => {
    setState({ ...state, isSave: true });
    let params = {
      task_id: state.details.id,
      title: state.title,
      description: state.description,
      clarified_description_to_client: state.clarified_description_to_client,
      credit_used: "",
      due_date: "",
    };

    if (params.title != "") {
      Api.updateTask(params)
        .then((result) => {
          props.reloadData(state.details.id, true);
          setState({ ...state, task_saved: true });
        })
        .catch((error) => {
          console.log(error);
          setState({ ...state, isSave: false });
        });
    } else {
      setState({ ...state, isSave: false });
    }
  };

  const companyProperties = (detailsRequestorObject) => {
    let requestor = detailsRequestorObject[0].companyId;
    Api.getCompanyPropertyOptions(requestor)
      .then((result) => {
        setState((prevState) => ({
          ...prevState,
          propertyID_options: result.data,
        })); // Used "prevState" first update wont get deleted from the first setState on useEffect function above
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestorUpdate = (e) => {
    let params = {
      task_id: state.details.id,
      requestor_id: e.value,
    };
    setState({ ...state, preload_requestor: true });
    Api.requestorUpdate(params)
      .then(({data}) => {
        let propertyID = data.properties,
          propertyID_options = [];

        for (let index = 0; index < propertyID.length; index++) {
          propertyID_options.push({
            value: propertyID[index].id,
            label: propertyID[index].name,
          });
        }

        if(data.website_id != state.propertyID.value) {
          setState({
            ...state,
            propertyID: data.website_id ? propertyID_options.find((option) => option.value == data.website_id) : ""
          });
        }

        setState((prevState) => ({
          ...prevState,
          propertyID_options: propertyID_options,
          requestor: e,
          preload_requestor: false,
          company: data.company,
          project: data.project,
        }));
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSave: false, preload_requestor: false });
        console.log(error);
      });
  };

  const pricing_methodUpdate = (e) => {
    setState({ ...state, pricing_method: e });
    let params = {
      task_id: state.details.id,
      pricing_id: e.value,
    };
    Api.pricingUpdate(params)
      .then((result) => {
        props.updateList();
        setState((prevState) => ({
          ...prevState,
          billableTimeHr: result.data.hr,
          billableTimeMin: result.data.min,
        }));
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const revertBoardChanges = (status) => {
    setState({ ...state, status: status, preload_status: true });
  };

  const statusUpdate = (e) => {
    const column = props.columns.find(
      (column) => column.id === state.details.status.id
    );
    const card = column.cards.find((card) => card.id === state.details.id);
    setState({ ...state, status: e, preload_status: true });
    let params = {
      task_id: state.details.id,
      status: e.value,
    };

    /* MODAL POPUP - FORM TO SUBMIT FOR QA */
    if (
      !state.details.report &&
      state.details.status.title == "In Progress" &&
      e.label == "Quality Assurance"
    ) {
      ReactSwal.fire({
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          content: "popupForm-QA-content",
          popup: "popupForm-modal",
          closeButton: "popupForm-close",
        },
        html: (
          <SubmitQA
            props={card}
            user_details={user_details}
            confirmButton={ReactSwal}
            report_details={(e) => (report = e)}
          />
        ),
      }).then((res) => {
        if (res.isConfirmed) {
          state.report_details = report;
          updateStatusAPI(params, e);
        }
      });
      return;
    }
    /* (END) MODAL POPUP - FORM TO SUBMIT FOR QA */

    /* MODAL POPUP - FORM TO SUBMIT FOR AWAITING CLIENT APPROVAL */
    if (
      (state.details.status.title == "In Review" ||
        state.details.status.title == "On-Hold") &&
      e.label == "Awaiting Client Approval"
    ) {
      ReactSwal.fire({
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          content: "popupForm-Email-content",
          popup: "popupForm-modal",
          closeButton: "popupForm-close",
        },
        html: (
          <SendEmail
            props={card}
            user_details={user_details}
            confirmButton={ReactSwal}
            email_details={null}
            revertBoardChanges={() => revertBoardChanges(column)}
          />
        ),
      }).then((res) => {
        if (res.isConfirmed) {
          return updateStatusAPI(params, e, column.id);
        } else {
          return revertBoardChanges(column);
        }
      });
      return;
    }
    /* (END) MODAL POPUP - FORM TO SUBMIT FOR AWAITING CLIENT APPROVAL */

    return updateStatusAPI(params, e, column.id);
  };

  const updateStatusAPI = (params, e, oldColumnId) => {
    Api.statusUpdate(params)
      .then((result) => {
        props.updateBoard(e, oldColumnId, params.task_id);
        // props.updateList();
        state.status = e;
        state.details.status.id = e.value;
        state.details.status.title = e.label;
        setTimeout(() => {
          setState({ ...state, preload_status: false, status: state.status });
        }, 2000);
      })
      .catch((error) => {
        setState({ ...state, isSave: false, preload_status: false });
        console.log(error);
      });
  };

  const requestTypeUpdate = (e) => {
    setState({ ...state, requestType: e });
    let params = {
      task_id: state.details.id,
      request_type_id: e.value,
    };
    Api.requestTypeUpdate(params)
      .then((result) => {
        console.log(result);
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const markDone = (e) => {
    setState((prevState) => ({
      ...prevState,
      is_done: state.is_done === 0 ? 1 : 0,
    }));

    let params = {
      task_id: state.details.id,
      is_done: state.is_done,
    };
    Api.doneUpdate(params)
      .then((result) => {
        console.log(result.data);
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const addSubTask = (e) => {
    setState({ ...state, isSubtask: true });
    Api.addSubTask(state.details.id)
      .then((result) => {
        let subtask = state.subtask;
        subtask.push(result.data);
        setState({ ...state, isSubtask: false, subtask: subtask });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveComment = (e) => {
    const { comment, details, comment_attachments } = state;
    const data = new FormData();

    if (comment_attachments.length > 0) {
      comment_attachments.forEach((attachment) => {
        data.append("files[]", attachment);
      });
    }

    setState({ ...state, isComment: true });
    data.append("task_id", details.id);
    data.append("body", comment);
    data.append("is_public", 0);
    sendReplyAPI(data);
  };

  const sendPublicReply = (e) => {
    const { comment, details, comment_attachments } = state;
    const data = new FormData();

    if (comment_attachments.length > 0) {
      comment_attachments.forEach((attachment) => {
        data.append("files[]", attachment);
      });
    }

    setState({ ...state, isComment: true });
    data.append("task_id", details.id);
    data.append("body", comment);
    data.append("is_public", 1);
    sendReplyAPI(data);
  };

  const sendReplyAPI = (data) => {
    Api.publicReply(data)
      .then((result) => {
        comments.unshift(result.data);
        setComments(comments);
        setState({
          ...state,
          comment: "",
          comment_attachments: [],
          clearRich: true,
          isComment: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setState({ ...state, isSubmit: false });
      });
  };

  const deleteComment = (e, index) => {
    let commentId = parseInt(e.target.getAttribute("comment_id"));

    Api.deleteComment(commentId)
      .then((result) => {
        comments.splice(index, 1);
        setComments([...comments]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateDueDateVariable = (e) => {
    setState((prevState) => ({
      ...prevState,
      dueDateUpdate: e,
    }));
  };

  const updateDueDate = async (e) => {
    let dueDate = moment(e).format("YYYY-MM-DD HH:mm:ss");
    console.log("dueDate =>", dueDate, e);
    setState((prevState) => ({
      ...prevState,
      preload_duedate: true,
    }));
    let params = {
      task_id: state.details.id,
      dueDate: dueDate,
    };
    await Api.dueUpdate(params)
      .then((result) => {
        // props.reloadData(state.details.id, false);
        setState((prevState) => ({
          ...prevState,
          dueDate: e,
          preload_duedate: false,
        }));
        props.updateList();
      })
      .catch((error) => {
        console.log(error);
      });

    await setState((prevState) => ({ ...prevState, preload_duedate: false }));
  };

  const handleChangeRich = (e) => {
    let stripped_string = e.replace(/<\/?(?!img)\w*\b[^>]*>|\s+/gi, ""); // strip the string for inputs that are ' ' but has '<p><br></p>' tags
    e = !stripped_string ? stripped_string : e; // use stripped_string to check if comment input is empty so that the button will be disabled
    setState((prevState) => ({ ...prevState, comment: e, clearRich: false }));
  };

  const handleImageRich = (e) => {
    let files = e,
      commentFiles = state.comment_attachments;

    for (let index = 0; index < files.length; index++) {
      let dataFiles = files[index];
      dataFiles.preview = URL.createObjectURL(files[index]);
      dataFiles.formattedSize = formatBytes(files[index].size);
      commentFiles.push(dataFiles);
    }

    setState({ ...state, comment_attachments: commentFiles });
  };

  const removeAttachment = (i) => {
    let attachments = state.comment_attachments;
    attachments.splice(i, 1);
    setState({ ...state, comment_attachments: attachments });
  };

  const updatesubTask = (e) => {
    let params = {
      task_id: e.target.getAttribute("task_id"),
      title: e.target.value,
      description: "",
      clarified_description_to_client: "",
      credit_used: "",
      due_date: "",
    };
    Api.updateTask(params)
      .then((result) => {
        setState({ ...state, isSave: false });
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const updateProperty = (e) => {
    setState({ ...state, propertyID: e });
    let params = {
      task_id: state.details.id,
      property_id: e.value,
    };
    Api.updateProperty(params)
      .then((result) => {
        const property = result.data.property;
        const primary = result.data.primary;
        const qa = result.data.qa;
        // state.propertyID
        if (!state.pricing_method && property.pricing_method_id) {
          const pricing_method =
            state.pricing_methods_options.filter(
              (opt) => opt.value === result.data.pricing_method_id
            ) ?? null;
          if (pricing_method) {
            setState((prevState) => ({
              ...prevState,
              pricing_method,
              primaryAdetails: primary,
              QAdetails: qa,
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            primaryAdetails: primary,
            QAdetails: qa,
          }));
        }
        props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const updateProposalButton = (e) => {
    if (state.task_saved) {
      return setState({
        ...state,
        clarified_description_to_client: e,
        showProposal: true,
      });
    } else {
      setState({
        ...state,
        clarified_description_to_client: e,
        showProposal: false,
      });
    }
  };

  const handleNotesOnChange = (e) => {
    if (state.typingNotesTimeout) {
      clearTimeout(state.typingNotesTimeout);
    }

    const el = e.target;
    el.style.height = el.scrollHeight + "px";

    setState({
      ...state,
      deploymentNotes: e.target.value,
      typingNotesTimeout: setTimeout(function () {
        updateDeploymentNotes();
      }, 2000),
    });
  };

  const toggleRequiresDeployments = () => {
    Api.toggleTaskRequiresDeployments(state.details.id)
      .then((result) => {
        console.log("requiresDeployments", result.data);
        setState({ ...state, requiresDeployments: result.data });
        // props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const updateDeploymentNotes = () => {
    let params = {
      task_id: state.details.id,
      notes: state.deploymentNotes,
    };

    Api.updateTaskDeploymentNotes(params)
      .then((result) => {
        console.log(result);
        // props.updateList();
      })
      .catch((error) => {
        setState({ ...state, isSave: false });
        console.log(error);
      });
  };

  const copyLink = (e) => {
    const description = state.details.title.replaceAll(' ', '%20');
    let urlLink = props.baseurlINK + "?id=" + state.details.id + "&task=" + state.details.task_number + "&description=" + description;
    var textField = document.createElement("textarea");
    textField.innerText = urlLink;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setState({ ...state, isCoppied: true });
    setState({ ...state, copyLink: true });
  };

  const seePropertyInfo = (e, property) => {
    e.preventDefault();
    e.stopPropagation();
    Api.getPropertyInfo(property[0].value)
      .then((result) => {
        document.body.classList.remove("minimize-form");
        setState({
          ...state,
          openPropertInfo: true,
          propertyInfo: result.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const minimizePropertyInfo = (event) => {
    event.stopPropagation();
    document.body.classList.add("minimize-form");
    setState({
      ...state,
      isMinimized: true,
    });
  };

  const openPropertInfo = (event) => {
    event.stopPropagation();
    document.body.classList.remove("minimize-form");
    setState({ ...state, openPropertInfo: true, isMinimized: false });
  };

  const closePropertyInfo = (event) => {
    event.stopPropagation();
    setState({ ...state, openPropertInfo: false });
  };

  const sendProposal = (event, props) => {
    const column = props.columns.find(
      (column) => column.id === state.details.status.id
    );

    event.stopPropagation();
    let confirm_details = { page: "proposal email for this task" },
      params = {
        id: state.details.id,
        clarified_description: state.clarified_description_to_client,
        description: state.description,
      };

    ConfirmSend(confirm_details).then((res) => {
      if (res.isConfirmed) {
        setState({ ...state, sendingProposal: true });
        if (state.estimateTimeHr == 0 && state.estimateTimeMin == 0) {
          setState({ ...state, sendingProposal: false });
          return ErrorAlert({ message: "Estimated Time should not be 0:0." });
        }

        sendProposalToAPI(params, column);
      }
    });
  };

  const sendProposalToAPI = (params, column) => {
    Api.sendProposal(params)
      .then((result) => {
        let statuses = state.statuses_options;
        let temp = props.details;
        let status = {};

        if (typeof statuses == "object" && statuses.length > 0) {
          statuses.some(function (stat, i) {
            if (stat.value === 2) return (status = stat);
          });
        }

        if (temp.status.value === 1) {
          temp.status = status;
          temp.new_status = 2;
        }

        temp.proposed_at = result.data;
        const details = temp;

        setState({
          ...state,
          details: details,
          showProposal: false,
          sendingProposal: false,
          status: status,
        });
        props.updateDetails(temp);
        props.updateBoard(status, column.id, params.id);
        SuccessAlert({ message: "Successfully sent proposal email!" });
      })
      .catch((error) => {
        setState({ ...state, sendingProposal: false });
        console.log(error);

        if (error.response && error.response.data)
          return ErrorAlert({ message: error.response.data });

        return ErrorAlert({ message: error });
      });
  };

  const updateTaskProgress = (progress) => {
    let params = { id: props.details.id, progress };

    Api.updateTaskProgress(params)
      .then((result) => {
        props.updateTaskAttributeInList(
          props.details.status.id,
          props.details.id,
          "progress",
          progress
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const nbValues = getValue();

    return (
      <components.ValueContainer {...props}>
        {children}
        {state.propertyID && (
          <i
            className="fa fa-info-circle property-info value-select"
            onClick={(e) => {
              seePropertyInfo(e, nbValues);
            }}></i>
        )}
      </components.ValueContainer>
    );
  };

  const Option = (props) => {
    return (
      <React.Fragment>
        <components.Option {...props}>
          <div>{props.data.label}</div>
          <div className="requestor-companyname">{props.data.subLabel}</div>
        </components.Option>
      </React.Fragment>
    );
  };

  const DropdownIndicatorRequestor = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {state.preload_requestor && (
          <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
        )}
      </components.DropdownIndicator>
    );
  };

  const DropdownIndicatorStatus = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {state.preload_status && (
          <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
        )}
      </components.DropdownIndicator>
    );
  };

  const requestDetailsLeft = () => {
    return (
      <React.Fragment>
        <div className="request-title-header">
          <h6 className="title-1">
            Request Title
            {state.title == "" ? (
              <span className="error-message">
                Request Title cannot be empty
              </span>
            ) : (
              <span className="required-ast">*</span>
            )}
          </h6>
          <Input
            placeholder="Tap here to start typing..."
            addClass="request-input"
            action={(e) => {
              setState({ ...state, title: e.target.value });
            }}
            value={state.title}
          />
        </div>

        <div className="row m-0">
          <div
            className={
              user_details.role === "coder"
                ? "col-12 request-form-description p-0"
                : "col-6 request-form-description p-0"
            }>
            <h6 className="proposal-description">Proposal Description</h6>
            <h6 className="title-1 ml-4 pt-2">Description</h6>
            <div
              className="ml-2 mt-2 mb-2 mr-1"
              style={{ background: "white" }}>
              <RichTextArea
                placeholder="Write more detail to this task..."
                addClass="request-form"
                action={(e) => {
                  setState({ ...state, description: e });
                }}
                value={state.description}
                index={2}
              />
            </div>
          </div>
          {console.log("TEST::", user_details.role)}
          {user_details.role !== "coder" && (
            <div className="col-6 request-form-clarified-description p-0">
              <h6 className="email-to-client">Email to client</h6>
              <h6 className="title-1 ml-4 pt-2">Scope</h6>
              <div
                className="ml-2 mt-2 mb-2 mr-1"
                style={{ background: "white" }}>
                <RichTextArea
                  disabled={props.details ? props.details.approved_at : false}
                  placeholder="Write more detail to this task..."
                  addClass="request-form"
                  action={updateProposalButton}
                  value={state.clarified_description_to_client}
                  index={3}
                />
              </div>
            </div>
          )}

          <div className="row mt-2">
            <button
              disabled={!state.showProposal && !state.sendingProposal}
              className="btn btn-primary btn-comment-btn"
              onClick={(e) => {
                sendProposal(e, props);
              }}>
              {state.sendingProposal ? (
                <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
              ) : (
                <i className="fas fa-paper-plane"></i>
              )}
              Send
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderTabDetails = () => {
    // state.form_type
    const { form_type } = state;
    switch (form_type) {
      case "request_details":
        return requestDetailsTab();
      case "reports":
        return reportsTab();
      case "activities":
        return activitiesTab();
      default:
        return requestDetailsTab();
    }
  };

  const requestDetailsTab = () => {
    console.log("requestor options", state.requestor_options);
    console.log("coder options", state.coders);
    return (
      <>
        <div className="col-6">
          <h6 className="title-1">Requestor</h6>
          <Select
            className="form-select ml-1"
            styles={customStyles}
            value={state.requestor}
            isDisabled={state.preload_requestor}
            onChange={requestorUpdate}
            options={state.requestor_options}
            components={{
              Option,
              IndicatorSeparator: () => null,
              DropdownIndicator: DropdownIndicatorRequestor,
            }}
          />
        </div>

        <div className="col-6">
          <h6 className="title-1">Website</h6>
          <Select
            className="form-select ml-1"
            styles={customStyles}
            value={state.propertyID}
            openMenuOnClick={false}
            onChange={updateProperty}
            options={state.propertyID_options}
            components={{
              ValueContainer,
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-6">
          <h6 className="title-1">Due Date</h6>
          {state.preload_duedate ? (
            <i className="bx bx-loader bx-spin font-size-18 ml-3 align-middle"></i>
          ) : (
            <DatepickerDiv
              updateDueDate={updateDueDate}
              date={state.dueDate}
              showTimeInput={true}
              dueDateUpdate={state.dueDateUpdate}
              updateDueDateVariable={updateDueDateVariable}
            />
          )}
        </div>

        <div className="col-6">
          <h6 className="title-1">Start Date</h6>
          {state.preload_duedate ? (
            <i className="bx bx-loader bx-spin font-size-18 ml-3 align-middle"></i>
          ) : (
            <DatepickerDiv
              updateDueDate={updateDueDate}
              date={state.startDate}
              showTimeInput={true}
              dueDateUpdate={state.dueDateUpdate}
              updateDueDateVariable={updateDueDateVariable}
            />
          )}
        </div>

        <div className="col-6 mt-3">
          <h6 className="title-1">Hubstaff Project</h6>
          <h6 className="title-2">
            <img src={hubstaffIcon} /> {state.project}
          </h6>
        </div>

        <div className="col-6 mt-3">
          <h6 className="title-1">Company</h6>
          <h6 className="title-2">{state.company}</h6>
        </div>

        <div className="col-6 mt-3">
          <h6 className="title-1">Status</h6>
          <Select
            className="form-select ml-1"
            styles={customStyles}
            value={state.status}
            isDisabled={state.preload_status}
            onChange={statusUpdate}
            options={state.statuses_options}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: DropdownIndicatorStatus,
            }}
          />
        </div>

        <div className="col-6 mt-3">
          <h6 className="title-1">Pricing Method</h6>
          <Select
            className="form-select ml-1"
            styles={customStyles}
            value={state.pricing_method}
            onChange={pricing_methodUpdate}
            options={state.pricing_methods_options}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        {/* <div className="col-6 mt-3">
          <TaskProgressSlider
            min={0}
            max={100}
            tooltips={false}
            start={state.progress}
            update={updateTaskProgress}
          />
        </div> */}

        <div className="col-6 mt-3">
          <h6 className="title-1">Request Type</h6>
          <Select
            className="form-select ml-1"
            styles={customStyles}
            value={state.requestType}
            onChange={requestTypeUpdate}
            options={state.requestType_options}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="border-spacer"></div>
        <div style={{ width: "100%" }}>
          <h6>Time Tracker</h6>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "space-between",
            }}>
            {/* BILLABLE TIME */}
            <TimePicker
              title="Billable Time"
              update={() => {}}
              hr={state.billableTimeHr}
              min={state.billableTimeMin}
              actualTimeAction={state.actualTimeAction}
              viewOnly={true}
            />

            {/* ACTUAL TIME */}
            <TimePicker
              title="Actual Time"
              update={updateTimeAPI}
              hr={state.actualTimeHr}
              min={state.actualTimeMin}
              actualTimeAction={state.actualTimeAction}
            />

            {/* ESTIMATED TIME */}
            <TimePicker
              title="Estimated Time"
              update={updateEstimateAPI}
              hr={state.estimateTimeHr}
              min={state.estimateTimeMin}
              actualTimeAction={state.actualTimeAction}
            />
          </div>
        </div>

        <div className="border-spacer"></div>
        <div style={{ width: "100%" }}>
          <h6>Team</h6>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "space-between",
            }}>
            <div className="col-4" style={{ padding: 0 }}>
              <h6 className="title-1">Admin</h6>
              {state.adminDetails ? (
                <ButtonUserOnly
                  action={selectAdmin}
                  actionDelete={deleteAdminAssignee}
                  details={state.adminDetails}
                  load={state.loadAdminAssignee}
                />
              ) : (
                <ButtonUser
                  action={selectAdmin}
                  load={state.loadAdminAssignee}
                />
              )}
              <div className="admin-assignee-content">
                {state.adminOpen && (
                  <ScroolUser
                    list={state.coders}
                    action={adminAssignee}
                    page="request-assignee-admin"
                  />
                  // <Select
                  //   className="form-select ml-1"
                  //   styles={customStyles}
                  //   value={state.adminDetails}
                  //   isDisabled={state.loadAdminAssignee}
                  //   onChange={adminAssignee}
                  //   options={state.coders}
                  //   components={{
                  //     Option,
                  //     IndicatorSeparator: () => null,
                  //     DropdownIndicator: DropdownIndicatorRequestor,
                  //   }}
                  // />
                )}
              </div>
            </div>
            {/* <div className="col-3" style={{padding: 0}}>
                  <h6 className="title-1">Project Manager</h6>
                  {state.primaryAdetails ? (
                    <ButtonUserOnly
                      action={selectPS}
                      actionDelete={deletePrimaryAssignee}
                      details={state.primaryAdetails}
                      load={state.loadPrimaryAssignee}
                    />
                  ) : (
                    <ButtonUser action={selectPS} load={state.loadPrimaryAssignee} />
                  )}
                  <div className="primary-assignee-content">
                    {state.psOpen && (
                      <ScroolUser
                        list={state.coders}
                        action={primaryAssignee}
                        page="request-assignee-primary"
                      />
                    )}
                  </div>
                </div> */}
            <div className="col-4" style={{ padding: 0 }}>
              <h6 className="title-1">Primary Assignee</h6>
              {state.primaryAdetails ? (
                <ButtonUserOnly
                  action={selectPS}
                  actionDelete={deletePrimaryAssignee}
                  details={state.primaryAdetails}
                  load={state.loadPrimaryAssignee}
                />
              ) : (
                <ButtonUser
                  action={selectPS}
                  load={state.loadPrimaryAssignee}
                />
              )}
              <div className="primary-assignee-content">
                {state.psOpen && (
                  <ScroolUser
                    list={state.coders}
                    action={primaryAssignee}
                    page="request-assignee-primary"
                  />
                )}
              </div>
            </div>
            <div className="col-4" style={{ padding: 0 }}>
              <h6 className="title-1">QA Assignee</h6>
              {state.QAdetails ? (
                <ButtonUserOnly
                  action={selectQA}
                  actionDelete={deleteQAAssignee}
                  details={state.QAdetails}
                  load={state.loadQAAssignee}
                />
              ) : (
                <ButtonUser action={selectQA} load={state.loadQAAssignee} />
              )}
              <div className="primary-qa-content">
                {state.qaOpen && (
                  <ScroolUser
                    list={state.coders}
                    action={qaAssignee}
                    page="request-assignee-qa"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="border-spacer"></div>

        <div
          className="col-12 pl-0 pr-0"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginRight: "10px",
            }}>
            <h6>Requires Deployments</h6>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Switch
              height={24}
              width={56}
              state={state.requiresDeployments ?? false}
              toggleStatus={toggleRequiresDeployments}
            />
          </div>
        </div>

        {state.requiresDeployments ? (
          <div style={{ width: "100%" }}>
            <h6 className="title-1" onClick={focusNotesTA}>
              Deployment Notes
            </h6>
            <textarea
              ref={notesTA}
              className={"deployment-notes"}
              value={state.deploymentNotes}
              onChange={(e) => handleNotesOnChange(e)}
              onBlur={(e) => handleFocusOut(e)}
            />
          </div>
        ) : null}
      </>
    );
  };

  const handleFocusOut = (e) => {
    if (e.target.value !== state.deploymentNotes) {
      if (state.typingNotesTimeout) {
        clearTimeout(state.typingNotesTimeout);
      }
      updateDeploymentNotes();
    }
    setState({ ...state, editDeplymentNotes: false });
  };

  const reportsTab = () => {
    const report_details = state.report_details;
    const user = report_details.created_by || "";
    console.log(report_details);
    return (
      <>
        {report_details ? (
          <div className="col-12 p-0">
            {/* CREATED BY / CREATED AT */}
            <div className="row report-created">
              <Avatar
                name={`${getFirstWord(user.firstname)}`}
                round={true}
                size="20"
                color="#f44336"
                className="btn-cirle-avatar"
              />
              <h2 className="created-by">
                {user.firstname} {user.surname}
              </h2>
              <h2 className="created-at">
                {moment(report_details.created_at).format(
                  "dddd, D MMMM, hh:mm A"
                )}
              </h2>
            </div>

            {/* REPORT NOTE */}
            <div className="row report-note">
              <p>{HtmlParser(report_details.report_note)}</p>
            </div>

            {/* REPORT ATTACHMENTS */}
            <div className="report-attachments">
              <div className="row report-attachments-title">
                <h2>
                  <img
                    src={attachment}
                    style={{ height: 15, marginRight: 8 }}
                  />{" "}
                  {report_details.files.length} attachments
                  {report_details.files.length > 0 && (
                    <span>
                      {" "}
                      |
                      <span
                        className="download-text"
                        onClick={() => multipleDownload(report_details.files)}>
                        {" "}
                        Download all
                      </span>
                    </span>
                  )}
                </h2>
              </div>

              <div className="report-files">
                <div className="file-container row m-0 mt-3 mb-2">
                  {report_details.files.map((file, i) => {
                    let extension = file.name.slice(
                      ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
                    );
                    console.log(extension);
                    return (
                      <div className="row m-0 p-1 col-6" key={i}>
                        <div className="file">
                          <img
                            src={extension == "pdf" ? pdfFile : file.url}
                            className="file-type"
                          />
                          <div className="file-details p-0">
                            <p
                              className="text-truncate file-name"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={file.name}>
                              {file.name}
                            </p>
                            <p className="file-size">
                              {formatBytes(file.size)}
                            </p>
                          </div>
                          <i
                            className="far fa-arrow-alt-circle-down file-download"
                            onClick={() => download(file.url, file.name)}></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 empty-reports">
            <img src={no_email_selected_icon} style={{ height: 120 }} />
            <h2>No Reports was Added</h2>
          </div>
        )}
      </>
    );
  };

  const activitiesTab = () => {
    return (
      <div className="activity-list">
        {state.activities.map((activity, index) => (
          <TaskActivity
            key={`activity-${index}`}
            description={activity.description}
            date={activity.date}
          />
        ))}
      </div>
    );
  };

  const requestDetailsRight = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 request-details-header">
            <button
              className={`btn btn-form-tab ${
                state.form_type == "request_details" ? "active" : ""
              }`}
              onClick={() =>
                setState({ ...state, form_type: "request_details" })
              }>
              Request Details
            </button>
            <button
              className={`btn btn-form-tab ${
                state.form_type == "reports" ? "active" : ""
              }`}
              onClick={() => setState({ ...state, form_type: "reports" })}>
              Reports
            </button>
            <button
              className={`btn btn-form-tab ${
                state.form_type == "activities" ? "active" : ""
              }`}
              onClick={() => setState({ ...state, form_type: "activities" })}>
              Activities
            </button>
            <button className="btn btn-primary" onClick={syncActualTime}>
              {state.isSyncing ? (
                <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
              ) : (
                <i className="fa fa-sync"></i>
              )}
              Sync Actual Time
            </button>
          </div>
        </div>
        <div
          className={`row p-3 ${
            state.form_type == "reports" ? "request-tab-full-height" : ""
          }`}>
          {renderTabDetails()}
        </div>

        <div className="row p-0">
          {state.form_type !== "activities" ? (
            <>
              {/* ATTACHMENTS */}
              <div className="col-12 p-0">{attachmentSection()}</div>
              {/* SUBTASKS */}
              <div className="col-12 mt-3 p-0">{subtaskSection()}</div>
            </>
          ) : null}

          <div className="col-12 p-0">
            {/* Comment Input Section */}
            {commentInputSection()}

            {/* Comment Section */}
            <div className="row comment-section">
              {comments &&
                comments.length > 0 &&
                comments.map((comment, i) => {
                  return commentSection(comment, i);
                })}
            </div>

            {/* {props.comments.loading && <LoadingDiv />} */}
          </div>

          {state.selectedFiles.map((f, i) => {
            return (
              <div className="col-12 mt-3 p-0">
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}>
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        {f.isImage && (
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={f.name}
                            src={f.preview}
                          />
                        )}
                      </Col>

                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  const subtaskSection = () => {
    return (
      <React.Fragment>
        <div className="subtask-div-container">
          {state.subtask.map((item, i) => {
            return (
              <div className="subtask-div" key={i}>
                <div className="row-1"></div>
                <div className="row-2">
                  <TextareaAutosize
                    placeholder="Add subtask title here..."
                    defaultValue={item.text}
                    task_id={item.id}
                    onBlur={updatesubTask}></TextareaAutosize>
                </div>
                <div className="row-3">
                  {/* <button task_id={item.id} onClick={deleteSubtask} className="btn btn-cicle btn-primary" >Delete</button> */}
                </div>
              </div>
            );
          })}
        </div>
        <div className="row pt-1 pb-1">
          <div className="col-12">
            <button className="btn btn-icon template" onClick={addSubTask}>
              {state.isSubtask ? (
                <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
              ) : (
                <i className=" fa fa-plus"></i>
              )}
              <span style={{ fontSize: 13, fontWeight: "400" }}>
                Add Subtask
              </span>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const attachmentSection = () => {
    let attachments = [];
    let taskAttachments = props.details.attachments ?? null;
    if (taskAttachments) {
      taskAttachments.map((att) => {
        attachments.push(att);
      });
    }

    if (comments) {
      comments.map((comment) => {
        if (comment.attachments) {
          comment.attachments.map((att) => {
            attachments.push(att);
          });
        }
      });
    }

    return (
      <React.Fragment>
        <table className="table-attachment table table-centered table-nowrap">
          <tbody>
            <tr>
              <td width="100">
                <span className="ml-2 attachment-header">ATTACHMENTS</span>
              </td>
              <td style={{ paddingRight: "26px" }}>
                <div className="border-center"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="attachment-container">
          <>
            <div>
              <div
                onClick={() => {
                  document.getElementById("icon-button-file").click();
                }}
                className="add-attachment-card">
                <img src={addAttachmentIcon} />
              </div>
            </div>
            {attachments.map((item, i) => {
              return (
                // <div className="col-md-6 attachment-item" key={i} >
                <div className="attachment-item" key={i}>
                  <AttachmentFile data={item} />
                </div>
              );
            })}
          </>
        </div>
      </React.Fragment>
    );
  };

  const commentInputSection = () => {
    return (
      <React.Fragment>
        <div className="row task-author">
          <div className="col-9">
            <Avatar
              name={state.nameIcon}
              round={true}
              size="32"
              color="#A6B0CF"
              image=""
            />
            <span className="author">
              {state.created_by} created this task.
            </span>
          </div>
          <div className="col-3 text-right">
            <div className="author-date" id="tooltip-date">
              {state.details ? moment(state.details.date).fromNow(true) : ""}
              <UncontrolledTooltip placement="top" target="tooltip-date">
                {state.details
                  ? moment(state.details.date).format(
                      "MMM DD, YYYY " + "@" + " h:mm a"
                    )
                  : ""}
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
        <div className="container-div card-bottom">
          <div className="right-div card-bottom-commit">
            <div className="comment-input-section">
              <RichText
                form="request"
                handleChange={handleChangeRich}
                handleImage={handleImageRich}
                removeAttachment={removeAttachment}
                value={state.comment}
                clear={state.clearRich}
                comment_attachments={state.comment_attachments}
              />

              <div className="btn-comment text-right">
                <button
                  id="btn-comment-btn"
                  className="btn btn-primary btn-comment-btn"
                  onClick={saveComment}
                  style={{
                    marginRight:
                      user_details.role === "super admin" ||
                      user_details.role === "admin" ||
                      user_details.role === "customer service agent"
                        ? "100px"
                        : 0,
                    width: "110px",
                  }}
                  disabled={state.isComment || !state.comment}>
                  {state.isComment ? (
                    <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                  ) : (
                    <>
                      <img src={commentIcon} style={{ marginRight: "10px" }} />
                      Comment
                    </>
                  )}
                </button>

                {(user_details.role === "super admin" ||
                  user_details.role === "admin" ||
                  user_details.role === "customer service agent") && (
                  <button
                    id="btn-comment-btn"
                    className="btn btn-primary btn-comment-btn"
                    onClick={sendPublicReply}
                    style={{ width: "90px" }}
                    disabled={state.isComment || !state.comment}>
                    {state.isComment ? (
                      <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                    ) : (
                      <>
                        <img src={emailIcon} style={{ marginRight: "10px" }} />
                        Email
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="div-followers">
              <span className="title">Followers</span>
              <Avatar
                name="Dwight Navarro"
                round={true}
                size="33"
                color="#A6B0CF"
                image=""
              />
              <button
                className="btn btn-cirle-dash ml-1"
                onClick={() => UnderDevelopmentAlert()}>
                <img src={plusIcon} style={{ height: 17 }} />
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  console.log("COMMENTS > b4 commentSection", comments);
  const commentSection = (comment, index) => {
    return (
      <React.Fragment key={index}>
        <div className="col-12 pt-3 pb-3 row-comments-container">
          <div className="row row-comments-list">
            <Avatar
              name={
                comment.sender?.firstname != null
                  ? `${comment.sender.firstname} ${comment.sender.lastname}`
                  : `${comment.sender?.email}`
              }
              round={true}
              size="32"
              color="#A6B0CF"
              image=""
            />
            <div className="col-10" style={{ paddingLeft: 10 }}>
              <span className="author">
                {comment.created_by?.firstname != null || comment.sender?.firstname != null
                  ? `${comment.created_by ? comment.created_by.firstname : comment.sender.firstname} ${comment.created_by ? comment.created_by.surname : comment.sender.lastname}`
                  : `${comment.created_by ? comment.created_by.email : comment.sender?.email}`}
              </span>{" "}
              <span className="date" id={"tooltip-date" + comment.id}>
                {moment(comment.created_at).fromNow(true)}{" "}
                <UncontrolledTooltip
                  placement="top"
                  target={"tooltip-date" + comment.id}>
                  {moment(comment.created_at).format(
                    "MMM DD, YYYY " + "@" + " h:mm a"
                  )}
                </UncontrolledTooltip>
              </span>
              <div
                style={{
                  color: "#$828282",
                  backgroundColor: "rgba(130,130,130,.12)",
                  padding: "0px 10px",
                  borderRadius: 25,
                  width: "fit-content",
                  height: 18,
                  fontSize: 10,
                }}>
                {comment.sender?.role === "user" ? "Client" : "Dev"}
              </div>
            </div>

            <div className="col-1 text-right delete">
              <i
                data-tip
                data-for={`message-tooltip`}
                className="fa fa-trash"
                comment_id={comment.id}
                onClick={(e) => deleteComment(e, index)}></i>
              <ReactTooltip
                id={`message-tooltip`}
                effect="solid"
                className="message-tooltip"
                offset={{ top: 30, right: 28 }}
                arrowColor="transparent"
                backgroundColor="#00000073">
                Delete Comment
              </ReactTooltip>
            </div>
          </div>

          <div className="body">
            {HtmlParser(comment.report_note ?? comment.body)}
            {comment.sender?.role === "user" &&
              comment.attachments.length > 0 && (
                <ImageAttachments data={comment.attachments} />
              )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const syncActualTime = () => {
    setState({ ...state, isSyncing: true });
    Api.syncActualTime(state.details.id)
      .then((result) => {
        // convert 0:0 to 00:00 actual time format
        let data = result.data;
        if (data.status && data.status === "validation failed") {
          setState({ ...state, isSyncing: false });
          Swal.fire({
            title: "Error!",
            text: data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        } else {
          let actual_time_used = data,
            actual_time_hours = "00",
            actual_time_minutes = "00";

          if (actual_time_used < 3600) {
            actual_time_minutes = Math.round(actual_time_used / 60);
          } else {
            actual_time_hours = Math.floor(actual_time_used / 3600);
            actual_time_used -= actual_time_hours * 3600;
            actual_time_minutes = Math.round(actual_time_used / 60);
          }

          if (typeof actual_time_hours !== "string") {
            if (actual_time_hours < 10) {
              actual_time_hours = "0" + actual_time_hours;
            }
          }

          if (typeof actual_time_minutes !== "string") {
            if (actual_time_minutes < 10) {
              actual_time_minutes = "0" + actual_time_minutes;
            }
          }

          setState({
            ...state,
            actualTimeHr: actual_time_hours,
            actualTimeMin: actual_time_minutes,
            isSyncing: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        const err = error.response
          ? error.response.data
          : "Something went wrong.";
        setState({ ...state, isSyncing: false });
        Swal.fire({
          title: "Error!",
          text: err,
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  };

  return (
    <React.Fragment>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="icon-button-file"
        type="file"
        onChange={(e) => handleImageChange(e)}
        multiple
      />
      {/* REQUEST FORM CONTAINER */}
      <div className="ticket-details" id="ticket-details">
        <div className="card" style={{ height: "100%" }}>
          {/* REQUEST FORM HEADER */}
          <div className="card-header">
            <div className="row" style={{ width: "100%" }}>
              <div className="col-7">
                <div
                  className="row"
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}>
                  {!state.is_done ? (
                    <div style={{ marginRight: "21px" }}>
                      <button
                        className="waves-effect waves-light btn btn-default highlight-btn-blue"
                        onClick={markDone}>
                        {" "}
                        <img src={iconCheck} /> &nbsp; Mark this Done{" "}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="waves-effect waves-light btn btn-primary highlight-btn-white"
                        onClick={markDone}>
                        {" "}
                        <img src={iconCheckWhite} /> &nbsp; Done{" "}
                      </button>
                    </div>
                  )}

                  <div className="row mt-10p">
                    <h6 className="title-1">ID No: </h6>
                    <h6 className="title-2">
                      {state.details ? state.details.task_number : ""}
                    </h6>
                  </div>

                  <div
                    style={{
                      width: "0px",
                      height: "16px",
                      border: "1px solid #ECECF5",
                      margin: "0 25px",
                    }}></div>

                  <div className="row mt-10p">
                    <h6 className="title-1">Date Requested: </h6>
                    <h6 className="title-2">
                      {state.details
                        ? moment(state.details.date).format("MM-DD-YYYY")
                        : ""}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-4 text-right">
                <button className="btn btn-icon template">
                  {" "}
                  <img src={template_icon} /> Template
                </button>
                <button
                  id="tooltip-attachement"
                  className="btn btn-icon"
                  onClick={() => {
                    UnderDevelopmentAlert();
                  }}>
                  {" "}
                  <img src={attachment} />{" "}
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"tooltip-attachement"}>
                    Attachments
                  </UncontrolledTooltip>
                </button>
                <button
                  className="btn btn-icon"
                  id="tooltip-subtask"
                  onClick={addSubTask}>
                  {" "}
                  <img src={subtaskIcon} />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"tooltip-subtask"}>
                    Subtask
                  </UncontrolledTooltip>
                </button>
                <button
                  className="btn btn-icon"
                  id="tooltip-copylink"
                  onClick={copyLink}>
                  {" "}
                  <img src={copyIcon} />
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"tooltip-copylink"}>
                    {" "}
                    {state.copyLink ? "Link Coppied" : "Copy Link"}{" "}
                  </UncontrolledTooltip>
                </button>
                &nbsp; &nbsp;
                <button
                  disabled={state.isSubmit || state.title == "" || state.isSave}
                  className="btn btn-primary"
                  onClick={editRequest}>
                  {state.isSave ? (
                    <i className="bx bx-loader bx-spin font-size-14 align-middle"></i>
                  ) : (
                    <i className="fa fa-save"></i>
                  )}
                  Save
                </button>
              </div>
              <div className="col-1 text-right close-div">
                <button
                  onClick={(e) => props.close(e)}
                  className="btn btn-icon">
                  {" "}
                  <img src={closeIcon} />{" "}
                </button>
              </div>
            </div>
          </div>

          {/* REQUEST FORM BODY */}
          <div className="card-body request-form">
            <div
              className="card-details"
              id="card-details-request"
              style={{ background: "#f8f8fb" }}>
              <div className="row m-0 request-form-body">
                {/* REQUEST DETAILS - LEFT */}
                <div className="col-7 p-0" style={{ height: "100%" }}>
                  {requestDetailsLeft()}
                </div>

                {/* REQUEST DETAILS - RIGHT */}
                <div
                  className="col-5 request-details"
                  style={{
                    background: "white",
                    borderLeft: "1px solid #efeeee",
                  }}>
                  {requestDetailsRight()}
                </div>
              </div>

              {/* TIME ADJUSTMENT POPUP FORM */}
              <div className="card-bottom">
                {state.taOpen && (
                  <TimeAdjustmentForm
                    tid={state.details.id}
                    update={updateTimeAdjustments.bind(this)}
                    close={() => {
                      setState({ ...state, taOpen: false });
                    }}
                    hr={state.timeAdjustmentsHr}
                    min={state.timeAdjustmentsMin}
                    isAdd={state.addTimeAdjustments}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {state.openPropertInfo && (
        <div className="requests-form">
          <PropertyInformation
            propertyDetails={state.propertyInfo}
            minimize={minimizePropertyInfo}
            openPropertInfo={openPropertInfo}
            close={closePropertyInfo}
            coders={state.coders}
            isMinimized={state.isMinimized ?? false}
          />
        </div>
      )}
    </React.Fragment>
  );
});

export default RequestForm;
