import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Api from "../../Utils/API";
import AddContact from '../../components/AddContact';
import LoadingIndicator from "../../components/LoadingIndicator";
import DataTable from "../../components/DataTable";
import DataTableMultipleSelect from "../../components/DataTableMultipleSelect";
import Avatar from "../../components/Avatar";
import imageIcon from "../../assets/images/inviteImage.png";
import { getFirstWord } from '../../Utils/helper';
import { Api as ApiImage } from '../../Utils/consts';
import { connect } from "react-redux";

class ContactList extends Component {

    constructor() {
        super();
        this.state = {
            newRequest: false,
            loading: true,
            addContact: false,
            list: [],
            company: [],
            toastData: { title:'', description: '' },
            id: '',
            isSubmit: false,
            userTypeOptions: [],
            userGroupOptions: [],
        }
        this.reloadData = this.reloadData.bind(this);
        this.search = this.search.bind(this);
        this.newContact = this.newContact.bind(this);
    }


    componentDidMount() {
        this.getUserTypeOptions();
        this.getList();
        this.getCompany();
        this.getUserGroupOptions();
    }

    componentDidUpdate(prev_props) {
        if(prev_props.contactSearchString !== this.props.contactSearchString) {
            // search
            this.search();
        }
    }

    search() {
        this.setState({ loading: true });
        Api.searchContacts(this.props.contactSearchString).then(result => {  
            this.setState({ list: result.data, loading:false });
        }).catch(error => {
            console.log(error);
        });
    }

    getUserTypeOptions() {
        // retrieve user type options
        Api.getUserTypeOptions().then(result => { 
            this.setState({userTypeOptions: result.data});
        }).catch(error => {
            console.log('contacts-list > getUserTypeOptions error', error)
        });
    }

    getUserGroupOptions() {
        // retrieve user group options
        Api.getUserGroupOptions().then(result => {
            this.setState({userGroupOptions: result.data});
            console.log('::getUserGroupOptions', result.data);
        }).catch(error => {
            console.log('contacts-list > getUserGroupOptions error', error)
        });
    }
    
    getList() {
        Api.getContacts().then(result => {
            console.log(result);
            setTimeout(() => {
                this.setState({ list: result.data, loading: false });
            }, 1000);
        }).catch(error => {
            console.log(error);
        });
    }

    getCompany() {
        Api.getCompany().then(result => {
            this.setState({company : result.data})
        }).catch(error => {
            console.log(error);
        });
    }


    minimize(event) {
        event.stopPropagation();
        document.body.classList.add('minimize-form');
    }

    addContact() {
        document.body.classList.remove('minimize-form');
        this.setState({ addContact: true });
    }

    openContact(event) {
        event.stopPropagation();
        document.body.classList.remove('minimize-form');
    }

    reloadData(){ 
        this.getList();
    }

    newContact(){
        this.setState({ addContact: false, toastData: { title:'Success!', description: `You successfully created a new contact!.` }  });
        this.showToast();
    }

    showToast() {
        var x = document.getElementById("snackbar-email");
        x.className = "show row notification-div";
        setTimeout(() => { this.closeToast(); }, 5000);
    }

    closeToast() {
        var x = document.getElementById("snackbar-email");
        x.className = x.className.replace("show", "");
    }


    render() {

        const dataTable = {
            columns: [
                {
                    label: "Name",
                    field: "name",
                },
                {
                    label: "Company",
                    field: "company",
                },
                {
                    label: "Email",
                    field: "email",
                },
                {
                    label: "SMS Number",
                    field: "number",
                },
                {
                    label: "STATUS",
                    field: "status",
                    width: 150
                },
                {
                    label: "Action",
                    field: "action",
                    width: 150
                }

            ],
            rows: []
        };

        let list = this.state.list;  // console.log('list', list);
        for (let index = 0; index < list.length; index++) {
            let status = '';
            let statusText = 'NO ACCOUNT';
            if (list[index].is_registered === 1) {
                status = 'status-completed';
                statusText = 'REGISTERED';
            }
            dataTable.rows.push(
                {
                    name: <div  className="container-profile">
                            <div className="row-1">
                            <Avatar
                                name={` ${getFirstWord(list[index].firstname)} `}
                                round={true}
                                size="32"
                                color="#f44336"
                                image={list[index].avatar ? ApiImage + list[index].avatar : ''}
                                className="btn-cirle-avatar"
                            />
                            </div>
                            <div className="row-2 text-truncate" style={{width: 250}}>
                                {list[index].firstname} {list[index].surname}
                                <p>{list[index].position}</p>
                            </div>
                        </div>,
                    company: list[index].company ? list[index].company.name : '',
                    email: list[index].email,
                    number: list[index].mobile,
                    status: <span className={`status ${status} `}>{statusText}</span>,
                    action: <Link to={`/contacts-profile?id=${list[index].id}`} className="btn-sm view-profile-btn"><i className="fa fa-search" ></i></Link>
                }
            );
        }

        var getDaysInMonth = function(month,year) {
            return new Date(year, month, 0).getDate();
        };

        let monnth =  getDaysInMonth(10, 2020);

        console.log('monnth', monnth);
        return (
            <React.Fragment>
                <div id="snackbar-email" onClick={this.closeToast.bind(this)} className="display-none"  >
                    <div className="col-5 notification-image flex-center" >
                        <img src={imageIcon} />
                    </div>
                    <div className="col-7 notification-details my-auto" >
                        <h5>{this.state.toastData.title}</h5>
                        <p>{this.state.toastData.description}</p>
                    </div>
                </div>
                
                {this.state.loading ? <LoadingIndicator loading={this.state.loading} /> : ''}
                <div className="page-content">
                    <div className="header-title" >
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button onClick={this.addContact.bind(this)} className="btn btn-primary" disabled={this.state.isSubmit}  >
                                    <i className="fa fa-plus" ></i>
                                    Add Contact
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid container-body white-bg">
                        {/* <DataTableMultipleSelect
                            data={dataTable}
                        /> */}
                        <DataTable
                            data={dataTable}
                            entriesOptions={[8, 30, 100, 500]} 
                            entries={8}
                        />
                    </div>
                </div>
                {
                    this.state.addContact ?
                        <AddContact
                            minimize={this.minimize.bind(this)}
                            openContact={this.openContact.bind(this)}
                            close={() => { this.setState({ addContact: false }) }}
                            company={this.state.company}
                            reloadData={this.reloadData}
                            newContact={this.newContact}
                            userTypeOptions={this.state.userTypeOptions}
                            userGroupOptions={this.state.userGroupOptions}
                        />
                        : ''
                }

            </React.Fragment>
        );
    }
}

const mapStatetoProps = ({Contact}) => {
    return { contactSearchString: Contact.contactSearchString };
};

export default connect(mapStatetoProps, (dispatch) => {
    return {
        // setsearch:(e)=> dispatch(searchContacts(e))
    }
})((ContactList));
